<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-form @submit.prevent="saveForm" method="post" id="product-form">
      <v-card>
        <v-card-title class="headline"
          >{{ isEditing ? "Edit" : "Add a new" }} Product</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-text-field
            label="Product Name"
            v-model="fields.name"
            outlined
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>
          <v-select
            label="Category"
            v-model="fields.category_id"
            :items="categories"
            item-value="id"
            item-text="name"
            outlined
            :error="errors.hasOwnProperty('category_id')"
            :error-messages="errors['category_id']"
          ></v-select>
          <v-textarea
            label="Description"
            v-model="fields.description"
            outlined
            :error="errors.hasOwnProperty('description')"
            :error-messages="errors['description']"
          ></v-textarea>
          <file-pond
            name="image"
            ref="image-upload"
            :server="server"
            accepted-file-types="image/jpeg, image/png"
            credits="false"
            :files="files"
            maxFiles="1"
            :error="errors.hasOwnProperty('image')"
            :error-messages="errors['image']"
            v-on:error="handleError"
            imagePreviewHeight="350"
            imageCropAspectRatio="1:1"
            imageResizeTargetWidth="350"
            imageResizeTargetHeight="350"
            :onaddfile="onaddfile"
            labelIdle='<b>Product Image</b><br />Drag & Drop your image file or <span class="filepond--label-action"> Browse </span>'
          />
          <v-text-field
            label="Price"
            v-model="fields.price"
            outlined
            prepend-inner-icon="mdi-currency-gbp"
            type="number"
            :error="errors.hasOwnProperty('price')"
            :error-messages="errors['price']"
          ></v-text-field>
          <v-combobox
            label="Sizes"
            v-model="fields.sizes"
            outlined
            multiple
            small-chips
          >
          </v-combobox>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
          <v-btn color="accent" text type="submit" :loading="loading"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { getApiUrl } from "@/utils";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginImageResize,
  FilePondPluginImageTransform
);

export default {
  components: {
    FilePond,
  },

  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
      product: {},
      fields: {
        name: null,
        category_id: null,
        description: null,
        price: null,
        sizes: [],
      },
      files: [],
      file: null,
      hasChanged: false,
    };
  },

  computed: {
    categories() {
      return this.$store.state.flightmode.products["categories"];
    },

    server() {
      const appId = this.$route.params.id;
      const token = localStorage.getItem("token");

      return {
        url: `${getApiUrl(appId)}/module/flightmode/products/image`,
        process: null,
        revert: null,
        remove: null,
        headers: {
          Authorization: "Bearer " + token,
        },
      };
    },
  },

  methods: {
    openForm: function (product = null) {
      if (product !== null) {
        this.isEditing = true;
        this.product = product;
        this.fields.name = product.name;
        this.fields.category_id = product.category.id;
        this.fields.price = product.price;
        this.fields.description = product.description;
        this.fields.sizes = product.sizes;
        this.setFile(product.image_url);
      }

      this.dialog = true;
    },

    saveForm: function () {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
      };

      if (this.isEditing) {
        payload.productId = this.product.id;
      }

      let formData = new FormData();
      formData.append("name", this.fields.name ?? "");
      formData.append("description", this.fields.description ?? "");
      formData.append("price", this.fields.price ?? "");
      formData.append("category_id", this.fields.category_id ?? "");

      for (let i = 0; i < this.fields.sizes.length; i++) {
        formData.append("sizes[]", this.fields.sizes[i]);
      }

      if (this.hasFile()) {
        formData.append("image", this.file);
      }

      this.$store
        .dispatch("flightmode/products/saveProduct", {
          formData,
          ...payload,
        })
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.product = {};
      this.errors = {};
      this.fields = {
        name: null,
        category_id: null,
        description: null,
        price: null,
        sizes: [],
      };
      this.files = [];
      this.file = null;
      this.hasChanged = false;
    },

    setFile(file) {
      this.files.push({
        source: file,
        options: {
          type: "local",
        },
      });
    },

    hasFile() {
      if (this.isEditing === true && this.hasChanged === false) {
        return false;
      }

      return this.file !== null;
    },

    onaddfile(error, file) {
      if (error) {
        return;
      }

      if (this.isEditing) {
        this.hasChanged = true;
      }

      this.file = file.file;
    },
  },
};
</script>
